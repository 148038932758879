import React from "react"
import { graphql, Link } from "gatsby"
import { useFlexSearch } from 'react-use-flexsearch'
import Layout from "../components/layout"
import Seo from "../components/seo"

const Search = ({data, location}) => {

  const params = new URLSearchParams(location.search);
  const queryString = params.get("query");

  const index = data.localSearchPages.index;
  const store = data.localSearchPages.store;
  const results = useFlexSearch(queryString, index, store);

  return (
    <Layout>
      <Seo title="Search" />
      <h2>{results.length} results for "{queryString}"</h2>
      <ul>
        {results.length > 0 && (
          results.map(result => (
            <li key={result.slug}>
              <Link to={result.slug}>{result.title}</Link> from <i>{result.source}</i> posted {result.date}
            </li>
        )))}
        {!(results.length > 0) && (
          <p>There are no results.</p>
        )}
      </ul>
    </Layout>
  )
}

export const query = graphql`
  query {
    localSearchPages {
      index
      store
    }
  }
`
export default Search;
